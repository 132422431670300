import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import * as styles from "./contact.module.scss";
import Layout from "../components/layout/layout";
import contactImage from "../images/Oscar4.jpeg";

const Contact = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulWebsiteConfiguration {
                edges {
                    node {
                        twitterHandle
                    }
                }
            }
        }
    `);

    const twitterHandle =
        data.allContentfulWebsiteConfiguration.edges[0].node.twitterHandle; //includes '@' i.e. @minimalsloth
    return (
        <Layout title="Contact">
            <div className={styles.image}>
                <img
                    src={contactImage}
                    width="182"
                    height="256"
                    alt="Ultimate Aqua"
                />
            </div>
            <div className={styles.body}>
                <p>
                    {"The best way to reach me is via "}
                    <a
                        href={`https://twitter.com/${twitterHandle.replace(
                            "@",
                            ""
                        )}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {twitterHandle}
                    </a>
                    {" on Twitter!"}
                </p>
            </div>
        </Layout>
    );
};

export default Contact;
